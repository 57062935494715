export default {
  public: {
    text01: 'Página principal',
    text02: 'Introducción de servicios',
    text03: 'Trabaja con nosotros',
    text04: 'Hacer un pedido',
    text05: 'Seguimiento de envío',
    text06: 'Contáctanos para Saber Más',
    text07: 'Infraestructura',
    text08: 'Podemos gestionar el proceso de principio a fin, o puede enviar sus mercancías directamente a nuestro centro de clasificación',
    text09: 'Experiencia del Cliente',
    text10: 'Seguimiento en tiempo real, envío de SMS a los clientes, proporcionando prueba fotográfica de la entrega',
    text11: 'Precios Predictivos',
    text12: 'Dile adiós a las páginas de tarifas adicionales y recargos. Ofrecemos precios simples y predictivos',
    text13: 'Síguenos',
    text14: 'Hacer un pedido',
    text15: 'Por favor, seleccione',
    text16: 'Envío exitoso',
    text17: 'Por favor, ingrese su nombre',
    text18: 'Por favor, ingrese su correo electrónico',
    text19: 'Por favor, ingrese su número de teléfono',
    text20: 'Por favor, ingrese la cantidad estimada de pedidos',
    text21: 'Por favor, ingrese la zona de entrega'
  },
  home: {
    text01: 'MIA Laxpress a su servicio',
    text02: '为您服务',
    text03: 'Por favor, introduce el número(s) de seguimiento, separa múltiples números con comas o saltos de línea',
    text04: 'Buscar',
    text05: 'Nuestras Ventajas',
    text06: 'Precios Más Adecuados',
    text07: 'Mayor Área de Entrega',
    text08: 'Entrega Más Rápida en el Mismo Día',
    text09: 'Tecnología Empoderada',
    text10: 'Ofrecemos planes de precios altamente competitivos',
    text11: 'Aseguramos que los clientes disfruten de servicios de alta calidad al menor costo',
    text12: 'Nuestra red de distribución cubre todo el país',
    text13: 'lo que garantiza que los paquetes se entreguen a tiempo, tanto en ciudades como en pueblos',
    text14: 'Gracias a un eficaz sistema logístico y a un diseño de rutas optimizado',
    text15: 'ofrecemos el servicio de entrega en el mismo día más rápido para satisfacer las necesidades urgentes de nuestros clientes',
    text16: 'a través de tecnología avanzada, como el análisis de big data y la inteligencia artificial',
    text17: 'mejoramos la eficiencia logística y la precisión del seguimiento de paquetes',
    text18: 'Tasa de Entrega a Tiempo',
    text19: 'Cantidad de Paquetes Entregados',
    text20: 'Población Cubierta',
    text21: 'Área de Cobertura',
    text22: 'Ahorro de Costos',
    text23: 'Satisfacción del Cliente',
    text24: 'Nuestra Área de Entrega',
    text25: 'Área autooperada',
    text26: 'Área de cooperación',
    text27: 'centro de distribución',
    text28: 'Región Metropolitana de Santiago',
    text29: 'Número de rastreo',
    text30: 'No hay número de seguimiento disponible. Por favor, introduzca un número para buscar.'
  },
  message: {
    text01: '¿Necesitas enviar un paquete urgentemente',
    text02: 'Aseguramos que los clientes disfruten de servicios de mensajería de alta calidad al menor costo',
    text03: 'Estás a punto de descubrir el secreto para mejorar la calidad de la entrega de paquetes y ahorrar más costos',
    text04: 'Imagina, hacer un pedido y entregarlo el mismo día, Qué tan emocionados estarán los clientes',
    text05: 'Nombre',
    text06: 'Correo Electrónico',
    text07: 'Número de Teléfono',
    text08: 'Cantidad de pedido estimada',
    text09: 'Enviar',
    text10: 'Ventajas del Servicio SP',
    text11: 'Ventajas del Servicio SNP',
    text12: 'Ventajas del Servicio FLEX',
    text13: 'Excelent eexperiencia de entrega',
    text14: 'Precios Más Competitivos',
    text15: 'Transporte más seguro y eficiente',
    text16: 'Menos Tiempo de Espera',
    text17: 'Mayor eficacia de entrega',
    text18: 'Costes logísticos más razonables',
    text19: 'Precios Más Competitivos',
    text20: 'Entrega en 2~4 días ',
    text21: 'Eficacia de entrega más rápida',
    text22: '¿Por qué Usar el Servicio SP?',
    text23: '¿Por qué Usar el Servicio SNP ?',
    text24: '¿Por qué Usar el Servicio FLEX ?',
    text25: 'Next-day servicio, Entrega rápida, segura y confiable al día siguiente. Gracias al uso de la tecnología y nuestro equipo bien entrenado, listo para cuidar de tus productos en cualquier momento.¡Confía en MIA, haz crecer tu negocio hoy!',
    text26: 'Garantizamos entrega segura y confiable después de recibir la solicitud de envío',
    text27: 'Solicitud de envío recibida antes de las 15:00 del mismo día y entregada antes de las 21:00 del mismo día',
    text28: 'Tamaño máximo del paquete 50*50*50, con un peso máximo de 30kg',
    text29: 'Tamaño máximo del paquete 30*20*50, con un peso máximo de 2kg',
    text30: 'Te destacarás en la competencia en MercadoLibre',
    text31: 'Tasa de satisfacción del cliente actual 100%',
    text32: 'Tasa de satisfacción del cliente actual 98%',
    text33: 'Mejora la visibilidad de tus productos en MercadoLibre y evita reclamaciones',
    text34: 'Proceso del Servicio SP',
    text35: 'Corte de pedido a las 12 en punto del mismo día.',
    text36: 'Recogida a más tardar a las 14:00',
    text37: 'Clasificación dentro del Almacén',
    text38: 'Despacha más tardía a las 12 PM del día siguiente',
    text39: 'Firma como muy tarde a las 20:00 del día siguiente',
    text40: 'Proceso del Servicio SNP',
    text41: 'Corte de Pedidos a las 2 PM en punto del mismo día',
    text42: 'Recogida más rápida a las 6 PM en punto del mismo día',
    text43: 'Clasificación dentro del Almacén',
    text44: 'Transporte',
    text45: 'Entrega más rápida a las 8 AM del día siguiente',
    text46: 'Entrega más rápida dentro de 2 días laborales',
    text47: 'Proceso del Servicio FLEX',
    text48: 'Corte de Pedidos a las 12 PM en punto del mismo día',
    text49: 'Recogida a más tardar a las 2 PM',
    text50: 'Clasificación dentro del Almacén',
    text51: 'Despacha más tardía a las 4 PM del mismo día',
    text52: 'Entrega más tardía a las 10 PM del mismo día'
  },
  join: {
    text01: 'Trabaja con MIA',
    text02: 'Como una empresa en rápido crecimiento, cubriendo más de 6000 códigos postales, nuestros clientes tienen millones de paquetes que necesitan ser entregados. Desarrolla tu propio negocio con nosotros',
    text03: 'Nombre',
    text04: 'Correo Electrónico',
    text05: 'Número de Teléfono',
    text06: 'Área de Entrega',
    text07: 'Enviar',
    text08: 'Únete a MIA por un futuro mejor',
    text09: 'Tarifas competitivas que te permiten controlar tus propios ingresos',
    text10: 'Registro fácil y rápido',
    text11: 'Establece tu propio horario, la mayoría de nuestros mensajeros entregan al menos cinco días por cada semana',
    text12: 'Usa tu propio vehículo',
    text13: 'Apoyo a la distribución',
    text14: 'Recogida en almacén',
    text15: 'Por qué trabaja con MIA?',
    text16: 'Creemos que el trabajo duro merece la pena, y ser empleado de MIA Small Parts incluye un salario generoso, bonificaciones y oportunidades de crecimiento. Recibirás un paquete de compensación competitivo。',
    text17: 'Estamos orgullosos de nuestra capacidad para entregar paquetes con una eficacia inigualable.Únete a nuestro equipo y experimenta la emoción de entregar paquetes a tiempo.',
    text18: 'Área de Entrega'
  }
}
