import { createI18n } from 'vue-i18n'
import en from '@/locales/en'
import zhCN from '@/locales/zh-cn'
import es from '@/locales/spanish'

const messages = {
  en: { ...en },
  zh: { ...zhCN },
  es: { ...es }
}
const savedLanguage = localStorage.getItem('language') || 'zh'
const i18n = createI18n({
  locale: savedLanguage, // 设置默认语言
  fallbackLocale: savedLanguage,
  messages
})

export default i18n
