export default {
  public: {
    text01: 'Homepage',
    text02: 'Service Introduction',
    text03: 'Join Us',
    text04: 'Place Order',
    text05: 'Track Shipment',
    text06: 'Contact Us to know More',
    text07: 'Infrastructure',
    text08: 'We can manage the end-to-end process, or you can deliver directly to our sorting center.',
    text09: 'Customer Experience',
    text10: 'Real-time tracking, sending SMS to customers, providing photos as proof of delivery',
    text11: 'Predictable Pricing',
    text12: 'Say goodbye to pages of extra fees and surcharges. We offer simple, predictable pricing',
    text13: 'Follow Us',
    text14: 'Place Order',
    text15: 'Please select',
    text16: 'Submission successful',
    text17: 'Please enter your name',
    text18: 'Please enter your email',
    text19: 'Please enter your phone number',
    text20: 'Please enter the estimated order quantity',
    text21: 'Please enter the delivery area'

  },
  home: {
    text01: 'MIA Laxpress at Your Service',
    // text02: '为您服务',
    text03: 'Please enter the tracking number(s), separate multiple numbers with commas or new lines',
    text04: 'Search',
    text05: 'Our Advantages',
    text06: 'Prices More Suitable',
    text07: 'Larger Delivery Range',
    text08: 'Fastest Delivery in Same-Day',
    text09: 'Technology Empowered',
    text10: 'We offer highly competitive pricing plans',
    text11: 'Ensuring customers enjoy high-quality services at the lowest cost',
    text12: 'Our distribution network covers the whole country',
    text13: 'ensuring that packages are delivered in a timely manner, no matter whether they are in cities or villages.',
    text14: 'Using an efficient logistics system and optimized route design',
    text15: 'we provide the fastest same-day delivery service to meet customers urgent needs',
    text16: 'Through advanced technology such as big data analysis and artificial intelligence',
    text17: 'we improve logistics efficiency and the accuracy of parcel tracking',
    text18: 'On-Time Delivery Rate',
    text19: 'Package Delivery Count',
    text20: 'Coverage Population',
    text21: 'Coverage Area',
    text22: 'Cost Savings',
    text23: 'Customer Satisfaction',
    text24: 'Our Delivery Range',
    text25: 'Self-operated area',
    text26: 'Cooperation area',
    text27: 'distribution center',
    text28: 'Santiago Metropolitan Region',
    text29: 'Waybill number',
    text30: 'No tracking number available. Please enter a number to search.'
  },
  message: {
    text01: 'Do you need to urgently send a package',
    text02: 'Ensuring customers enjoy high-quality delivery services at the lowest cost',
    text03: 'You are about to discover the secret to improving parcel delivery quality and saving more costs',
    text04: 'Imagine, placing an order and delivering it the same day, how excited will the customers be',
    text05: 'Name',
    text06: 'Email',
    text07: 'Phone Number',
    text08: 'Estimated Order Quantity',
    text09: 'Submit',
    text10: 'Advantages of SP Service',
    text11: 'Advantages of SNP Service',
    text12: 'Advantages of FLEX Service',
    text13: 'Excellent delivery experience',
    text14: 'More Competitive Prices',
    text15: 'Safer and more efficient transportation',
    text16: 'Less Waiting Time',
    text17: 'Higher delivery efficiency',
    text18: 'More reasonable logistics costs',
    text19: 'More Competitive Prices',
    text20: 'Delivery within 2~4 days',
    text21: 'Faster delivery efficiency',
    text22: 'Why Use SP Service ?',
    text23: 'Why Use SNP Service ?',
    text24: 'Why Use FLEX Service ?',
    text25: 'Next-day delivery, fast, safe, and reliable. Thanks to the use of technology and our well-trained team ready to take care of your products at any time. Trust MIA, make your business grow today!',
    text26: 'We guarantee safe and reliable delivery after receiving the shipping request',
    text27: 'Shipment request received by 15:00 on the same day and delivered by 21:00 on the same day',
    text28: 'Maximum package size 50*50*50, and weight at 30kg',
    text29: 'Maximum package size 30*20*50, and weight at 2kg',
    text30: 'You will stand out in the competition on MercadoLibre',
    text31: 'Current customer satisfaction rate 100%',
    text32: 'Current customer satisfaction rate 98%',
    text33: 'Enhance the visibility of your products on MercadoLibre and avoid claims',
    text34: 'SP Service Process',
    text35: 'Order Cut-off at 12 PM on the same day',
    text36: 'Latest pick-up by 2 PM',
    text37: 'Sorting in the Warehouse',
    text38: 'Latest delivery by 12 PM the next day',
    text39: 'Sign for it at 20:00 the next day at the latest',
    text40: 'SNP Service Process',
    text41: 'Order Cut-off at 2 PM on the same day',
    text42: 'Fastest pick-up by 6 PM on the same day',
    text43: 'Sorting in the Warehouse',
    text44: 'Transport',
    text45: 'Fastest delivery by 8 AM the next day',
    text46: 'Fastest signing within 2 working days',
    text47: 'FLEX Service Process',
    text48: 'Order Cut-off at 12 PM on the same day',
    text49: 'Latest pick-up by 2 PM',
    text50: 'Sorting in the Warehouse',
    text51: 'Latest delivery by 4 PM the same day',
    text52: 'Latest signing by 10 PM the same day'
  },
  join: {
    text01: 'Join MIA',
    text02: 'As a rapidly growing company, covering over 6000 postal codes, our clients have millions of parcels that need delivery. Grow your own business with us',
    text03: 'Name',
    text04: 'Email',
    text05: 'Phone Number',
    text06: 'Delivery Area',
    text07: 'Submit',
    text08: 'Join MIA for a better future',
    text09: 'Competitive fees that allow you to control your own income',
    text10: 'Easy and quick registration',
    text11: 'Set your own schedule, most of our couriers deliver at least five days a week',
    text12: 'Use your own vehicle',
    text13: 'Distribution Support',
    text14: 'Warehouse pickup',
    text15: 'Why Join mia？',
    text16: 'We believe that hard work pays off, and being an MIA Small Parts Clerk includes a generous salary, bonuses, and growth opportunities. You will receive a competitive compensation package。',
    text17: 'We pride ourselves on our ability to deliver packages with unparalleled efficiency.Join our team and experience the thrill of delivering packages on time.',
    text18: 'Delivery Area'
  }
}
