export default {
  public: {
    text01: '首页',
    text02: '服务介绍',
    text03: '加入我们',
    text04: '去下单',
    text05: '轨迹查询',
    text06: '联系我们，了解更多',
    text07: '基础设施',
    text08: '我们可以管理端到端的流程，或者您可以直接将货物送到我们的分拣中心',
    text09: '客户体验',
    text10: '实时跟踪、向客户发送短信、提供送货照片证明',
    text11: '预测定价',
    text12: '告别一页页的额外费用和附加费。我们提供简单、可预测的定价',
    text13: '快来关注我们～',
    text14: '立即下单',
    text15: '请选择',
    text16: '提交成功',
    text17: '请输入姓名',
    text18: '请输入邮箱',
    text19: '请输入手机号',
    text20: '请输入预估单量',
    text21: '请输入派送区域'
  },
  home: {
    text01: '幂雅物流',
    text02: '为您服务',
    text03: '请输入运单号，多个运单号逗号隔开或换行',
    text04: '搜索',
    text05: '我们的优势',
    text06: '更合适价格',
    text07: '更大的配送范围',
    text08: '最快当日送达',
    text09: '科技赋能',
    text10: '我们提供极具竞争力的价格方案',
    text11: '确保客户以最优惠的成本享受高质量的快递服务',
    text12: '我们的配送网络覆盖全国',
    text13: '确保无论城市还是乡村包裹都能及时送达',
    text14: '利用高效的物流系统和优化的路线设计',
    text15: '我们提供最快当日送达服务满足客户的紧急需求',
    text16: '通过先进的科技手段，如大数据分析和人工智能',
    text17: '我们提高了物流效率和包裹追踪的精准度',
    text18: '准时签收率',
    text19: '签收包裹数',
    text20: '覆盖人口',
    text21: '覆盖区域',
    text22: '节省成本',
    text23: '客户满意度',
    text24: '我们配送的范围',
    text25: '自营区域',
    text26: '合作区域',
    text27: '分拨中心',
    text28: '圣地亚哥首都区',
    text29: '运单号',
    text30: '暂无运单，请输入单号查询'
  },
  message: {
    text01: '你需要紧急寄送包裹吗',
    text02: '确保客户以最优惠的成本享受高质量的快递服务',
    text03: '你即将发现提升包裹配送质量节省更多资金的秘诀',
    text04: '想象一下，当天下单当天送达客户将会有多兴奋',
    text05: '姓名',
    text06: '邮箱',
    text07: '手机号',
    text08: '预估单量',
    text09: '提交',
    text10: 'SP服务的优势',
    text11: 'SNP服务的优势',
    text12: 'FLEX服务的优势',
    text13: '更优的配送体验',
    text14: '更具优势的价格',
    text15: '更安全高效的运输',
    text16: '更少的等待时间',
    text17: '更高的派送效率',
    text18: '更合理的物流费用',
    text19: '更具优势的价格',
    text20: '2~4天内送达 ',
    text21: '更快速的交付效率',
    text22: '为什么要使用SP服务？',
    text23: '为什么要使用SNP服务？',
    text24: '为什么要使用FLEX服务？',
    text25: '第二天快速、安全和可靠地交货。这要归功于技术的使用和我们训练有素的团队随时照顾您的产品。 相信 MIA，今天就发展您的业务！',
    text26: '我们保证在发货请求后安全可靠地交货。',
    text27: '当日15点前收到发货请求，当天21点前送达',
    text28: '最大可以配送50*50*50的、重量30kg的包裹。',
    text29: '最大可以配送30*20*50的、重量2kg的包裹。',
    text30: '您将在MercadoLibre的竞争中脱颖而出。',
    text31: '目前客户好评率100%。',
    text32: '目前客户好评率98%。',
    text33: '提升您的产品在MercadoLibre的知名度，并避免索赔。',
    text34: 'SP服务流程',
    text35: '当天12点截单',
    text36: '最迟14点揽收',
    text37: '仓内分拣',
    text38: '最迟次日12点派送',
    text39: '最迟次日20点签收',
    text40: 'SNP服务流程',
    text41: '当天14点截单',
    text42: '最快当日18点揽收',
    text43: '仓内分拣',
    text44: '运输',
    text45: '最快次日8点派送',
    text46: '最快2个工作日签收',
    text47: 'FLEX服务流程',
    text48: '当天12点截单',
    text49: '最迟14点揽收',
    text50: '仓内分拣',
    text51: '最迟当日16点派送',
    text52: '最迟当日22点签收'
  },
  join: {
    text01: '加入MIA',
    text02: '作为一家快速发展的企业，覆盖 6000 多个邮政编码，我们的客户有数百万个包裹需要投递。与我们一起发展您自己的业务。',
    text03: '姓名',
    text04: '邮箱',
    text05: '手机号',
    text06: '预估单量',
    text07: '提交',
    text08: '加入MIA，拥有更好的未来',
    text09: '有竞争力的费率，让您可以控制自己的收入。',
    text10: '注册简单快捷。',
    text11: '制定你自己的时间表，我 们的大多数司机每周5天 至少行驶一条路线。',
    text12: '使用您自己的车辆。',
    text13: '配送支持',
    text14: '仓库提货',
    text15: '为什么要加入mia？',
    text16: '我们相信努力工作会得到回报，作为MIA小件员包括优厚的工资、奖金和成长机会。您将获得具有竞争方的薪酬待遇',
    text17: '我们为无与伦比的递送包裹的效率而自豪。加入我们的团队，体验以速度递送包裹的快感。',
    text18: '派送区域'
  }
}
